import { useEffect } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import {
  PRICE_SPIDER_ACCOUNT,
  PRICE_SPIDER_CONFIG_LOCAL,
  PRICE_SPIDER_CONFIG_ONLINE,
  PRICE_SPIDER_SCRIPT_URL,
} from 'helpers/constants/priceSpider';
import styles from './PriceSpider.module.scss';

export const PriceSpiderHead = () => {
  return (
    <>
      <meta name="ps-account" content={PRICE_SPIDER_ACCOUNT} />
      <meta name="ps-country" content="US" />
      <meta name="ps-language" content="en" />
      <script src={PRICE_SPIDER_SCRIPT_URL} async id="ps-script" />
    </>
  );
};

interface PriceSpiderSnippetProps {
  sku: string;
}

export const PriceSpiderSnippet = ({ sku }: PriceSpiderSnippetProps) => {
  useEffect(() => {
    sku && window.PriceSpider?.rebind?.();
  }, [sku]);

  return (
    <Container data-testid="where-to-buy" className={styles['where-to-buy']}>
      <h2 className={styles.title}>Where To Buy</h2>
      <Tabs id="wtb-tabs" className={styles.tabs} defaultActiveKey="local">
        <Tab eventKey="local" title="Local">
          <div
            data-testid="spider-local"
            className="ps-widget"
            ps-config={PRICE_SPIDER_CONFIG_LOCAL}
            ps-sku={sku}
          ></div>
        </Tab>
        <Tab eventKey="online" title="Online">
          <div
            data-testid="spider-online"
            className="ps-widget"
            ps-config={PRICE_SPIDER_CONFIG_ONLINE}
            ps-sku={sku}
          ></div>
        </Tab>
      </Tabs>
    </Container>
  );
};
