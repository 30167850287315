import React from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { AcceptedQueryTypes } from '@commercetools/frontend-sdk/lib/types/Query';
import ContentstackLivePreview from '@contentstack/live-preview-utils';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { BREAD_SCRIPT_URL } from 'helpers/constants/bread';
import { useFormat } from 'helpers/hooks/useFormat';
import { sdk } from 'sdk';
import { createClient, ResponseError } from 'frontastic';
import { FrontasticRenderer } from 'frontastic/lib/renderer';
import { tastics } from 'frontastic/tastics';
import styles from './slug.module.css';
import GTMSnippet from '../components/headless/GTMSnippet';
import { PriceSpiderHead } from '../components/headless/PriceSpiderSnippet';
import TurnToSnippet from '../components/headless/TurnToSnippet';
import { Log } from '../helpers/errorLogger';

ContentstackLivePreview.init();

type SlugProps = {
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
};

export default function Slug({ data }: SlugProps) {
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();
  const productSkuId = router.asPath?.split('/p/')[1]?.split('/')?.[1] || '';

  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data?.ok && data?.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  const domain = process.env.NEXT_PUBLIC_PARTS_CANONICAL_URL || '';
  const cleanedPath = router.asPath.split('?')[0].split('#')[0];
  const canonicalUrl = domain ? `${domain}${cleanedPath}` : null;

  return (
    <>
      <Head>
        <title>
          {formatMessage({ id: 'meta.title', defaultMessage: 'Cameras and photography equipment from Nikon' })}
        </title>
        <meta
          name="description"
          content={formatMessage({ id: 'meta.desc', defaultMessage: 'Cameras and photography equipment from Nikon' })}
        />
        {productSkuId !== '' ? <PriceSpiderHead /> : ''}

        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <GTMSnippet />
      </Head>
      {productSkuId !== '' ? <TurnToSnippet sku={productSkuId} /> : ''}

      <FrontasticRenderer data={data} tastics={tastics} wrapperClassName={styles.gridWrapper} />
      <Script src={BREAD_SCRIPT_URL} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({
  params,
  locale,
  query,
  req,
  res,
  resolvedUrl,
}) => {
  sdk.configureForNext(locale as string);

  const frontastic = createClient();

  // if LOCAL or DEV environment, use "DEVELOPMENT ONLY" api hub credentials
  // QA will use the DEVELOPMENT api hub, but not the "DEVELOPMENT ONLY" credentials
  // UAT will use the STAGING api hub (staging api hub will ignore the "DEVELOPMENT ONLY" credentials
  // PROD will use the PRODUCTION api hub (staging api hub will ignore the "DEVELOPMENT ONLY" credentials
  req.headers['api-env'] =
    req.headers.host?.startsWith('localhost') ||
    req.headers.host?.startsWith('nikonparts-dev') ||
    req.headers.host?.startsWith('dev.parts.nikon')
      ? 'development'
      : '';

  const [data, categories] = await Promise.all([
    frontastic.getRouteData(params?.slug as string[], query as AcceptedQueryTypes, req, res),
    frontastic.getCategories(req, res),
  ]);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data && 'statusCode' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error(new Error('Error retrieving data: '), data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  const protocol = req.headers.referer?.split('://')[0] || 'https';

  const serverUrl = `${protocol}://${req.headers.host}${resolvedUrl}`;

  const serverState = null;

  return {
    props: {
      data: { ...data, categories, serverUrl, serverState } || null,
      locale: locale,
      ...(await serverSideTranslations(locale as string, [
        'account',
        'cart',
        'checkout',
        'common',
        'customer-support',
        'error',
        'newsletter',
        'orders',
        'payment',
        'product',
        'success',
        'thank-you',
        'wishlist',
      ])),
    },
  };
};
